import React from 'react'

export const Investcurosel = () => {
  return (
    <section className="testimonials-section t_desktop d-block">
    <div className="container">

      <div className="heading-title">
      <p><span></span> </p>
        <h2>Testimonials of Investors</h2>
      </div>
    <div className="testimonial_wraper owl-carousel">
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p>
        I have pre-registered as an investor on Growth91
       <sup>®</sup> platform. Excited
        to start investing in Startups.
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/mukesh-mer.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">Mukesh Mer</a>
          <small>
            N M <br /> Holding
          </small>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p>
        I am a regular investor on Growth Sense and very happy with
        kind of returns generated on my investments. Looking forward
        to equally exciting opportunities at Growth91
       <sup>®</sup>
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/ramesh-babu.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">Ramesh Babu</a>
          <small>
            Country Head <br /> Government Banking, Axis Bank
          </small>
        </div>
      </div>
    </div>

    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p>
        Knowing the team since so many years, especially after
        experiencing their skill in deal curation; looking forward to
        some exciting deals on the platform.
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/mitul.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">Mitul Jhaveri</a>
          <small>
            Director Finance, <br /> Regal Rexnord India
          </small>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#313131",
          fontFamily: '"Nunito", sans-serif',
          padding: "20px 0",
          textAlign: "justify",
          lineHeight: "24px",
        }}
      >
        Discovering hidden gems with Growth91 is like uncovering
        beautiful teasure. The platform's knack for identifying unique
        and promising startups gives me a sense of being part of an
        exclusive club.It's not just about numbers; it's about the
        thrill of finding the next big thing in the startup world.
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/hrish.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">Hirish Shipurkar</a>
          <small>
            Head India, <br /> GPS FIG, HSBC Bank
          </small>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#313131",
          fontFamily: '"Nunito", sans-serif',
          padding: "20px 0",
          textAlign: "justify",
          lineHeight: "24px",
        }}
      >
        Investing through Growth91 feels like having insider access to
        a world top-tier opportunities. the platform's ability to
        consistently bring high-quality deals to the table to showcase
        a deep understanding of market trends and a commitment to
        providing investors with access to cream of the crop
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/jaison.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">JAISON TITUS</a>
          <small>
            SOFTWARE DEVLOPMENT, <br />
            Engineer Amazon AWS and
            <br /> Amazon Robotics,USA
          </small>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p
        style={{
          fontSize: "14.6px",
          fontWeight: "400",
          color: "#313131",
          fontFamily: '"Nunito", sans-serif',
          padding: "20px 0",
          textAlign: "justify",
          lineHeight: "24px",
        }}
      >
        what I appreciate most about Growth91 is the Caliber of its
        advisory board.Sanjay Sarda,Jimish Kapadia Asit oberoi and
        other they aren't figerHeads they're active contributors,shapeing the plateform's approach to
        investments.it's like having a personalised team of mentors,
        ensuring that every investment decision benifits from a wealth
        of collective exprience and foresight
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/kush.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">KUSH SHRIVASTAVA</a>
          <small>
            Co Founder Quiklo, <br /> Fintech Expert
          </small>
        </div>
      </div>
    </div>
    <div className="item">
      <div className="quotes">
        <img src="./web/images/quote.svg" alt="img" />
      </div>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "400",
          color: "#313131",
          fontFamily: '"Nunito", sans-serif',
          padding: "20px 0",
          textAlign: "justify",
          lineHeight: "24px",
        }}
      >
        The caliber of deals curated by Growth91 is nothing shorts of
        exceptional. It's not just about quality;it's the meticulous
        selection process that ensure each investment opportuntiy is a
        potential game-changer. Quality over quantity truly defines
        the investment landscapes on this platform. hope they
        continueto do it in future also
      </p>
      <div className="media">
        <div className="images">
          <img
            src="./assets/images/testimonials/Prakash.jpg"
            alt="img"
          />
        </div>
        <div className="media-body">
          <a href="#">PRAKASH ROHERA</a>
          <small>
            INTERNATIONAL Corpoate <br />
            Trainer and Coach
          </small>
        </div>
      </div>
    </div>
  </div>
  </div>
        </section>
  )
}
